import React from 'react'

const Formulario = () => {
  return (
    <div className="col-10 col-lg-6 mx-auto">
      <form
        className="my-5"
        action="https://formspree.io/f/xnqlgdbj"
        method="POST"
      >
        <div class="my-4">
          <label for="nombre" class="form-label">
            Nombre Completo:
          </label>
          <input
            name="name"
            type="text"
            class="form-control"
            id="nombre"
            aria-describedby="emailHelp"
            placeholder="Escriba aqui su nombre completo..."
          />
        </div>
        <div class="my-4">
          <label for="email" class="form-label">
            Correo Electronico:
          </label>
          <input
            name="email"
            type="email"
            class="form-control"
            id="email"
            placeholder="Escriba aqui su correo electronico..."
          />
        </div>
        <div class="my-4">
          <label for="movil" class="form-label">
            Numero Movil:
          </label>
          <input
            name="movil"
            type="number"
            class="form-control"
            id="movil"
            aria-describedby="emailHelp"
            placeholder="Escriba su numero de celular..."
          />
        </div>
        <div class="my-4">
          <label for="mensaje" class="form-label">
            Mensaje:
          </label>
          <textarea
            name="mensaje"
            class="form-control"
            placeholder="Escriba aqui el me saje que desea enviar...."
            id="mensaje"
            style={{ height: '160px' }}
          ></textarea>
        </div>
        <button type="submit" class="btn hero-text--button offset-3 col-6 my-4">
          Enviar Mensaje
        </button>
      </form>
    </div>
  )
}

export default Formulario
