import React from 'react'
import PropTypes from 'prop-types'

const CallToActionContacto = ({
  iconTitle1,
  iconTitle2,
  iconTitle3,
  title1,
  title2,
  title3,
  list1Icon1,
  list1Icon2,
  list1Icon3,
  list1Div1,
  list1Div2,
  list1Div3,
  list2Icon1,
  list2Icon2,
  list2Icon3,
  list2Div1,
  list2Div2,
  list2Div3,
  list3Icon1,
  list3Icon2,
  list3Icon3,
  list3Div1,
  list3Div2,
  list3Div3,
}) => {
  const CallToactionComponente = ({
    title,
    iconTitle,
    lista1,
    lista2,
    lista3,
    Icon1,
    Icon2,
    Icon3,
  }) => (
    <div className="col-12 d-flex flex-column flex-lg-row mx-auto my-lg-5 my-0 py-2">
      <div className="direccion mb-2 my-lg-0">
        <div className="title d-flex align-items-end">
          <img src={iconTitle} alt="" />
          <h3 className="title-h3 my-0 px-2">{title}</h3>
        </div>
        <div className="lista my-3">
          <ul className="listaUl p-0">
            <li className="listaUl-li d-flex align-items-center my-3">
              <img src={Icon1} className="" alt="icono-direccion" />
              <p className="listaUl-li--p px-3 my-auto ">{lista1}</p>
            </li>
            <li className="listaUl-li d-flex align-items-center my-3">
              <img src={Icon2} className="" alt="icono-direccion" />
              <p className="listaUl-li--p px-3 my-auto ">{lista2}</p>
            </li>
            <li className="listaUl-li d-flex align-items-center my-3">
              <img src={Icon3} className="" />
              <p className="listaUl-li--p px-3 my-auto ">{lista3}</p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
  return (
    <div className="container-fluid py-4" id="CallToActionContacto">
      <div className="callToAction d-flex flex-column flex-lg-row col-10 mx-auto ">
        <div className="direcciones col-10 col-lg-4 ">
          <CallToactionComponente
            title={title1}
            iconTitle={iconTitle1}
            lista1={list1Div1}
            lista2={list1Div2}
            lista3={list1Div3}
            Icon1={list1Icon1}
            Icon2={list1Icon2}
            Icon3={list1Icon3}
          />
        </div>
        <div className="correos col-12 col-lg-5 my-2 my-lg-0 ">
          <div className="direcciones col-12 col-lg-4 ">
            <CallToactionComponente
              title={title2}
              iconTitle={iconTitle2}
              lista1={list2Div1}
              lista2={list2Div2}
              lista3={list2Div3}
              Icon1={list2Icon1}
              Icon2={list2Icon2}
              Icon3={list2Icon3}
            />
          </div>
        </div>
        <div className="celulares col-12 col-lg-3 mt-2 my-lg-0 ">
          <div className="direcciones col-12 col-lg-4">
            <CallToactionComponente
              title={title3}
              iconTitle={iconTitle3}
              lista1={list3Div1}
              lista2={list3Div2}
              lista3={list3Div3}
              Icon1={list3Icon1}
              Icon2={list3Icon2}
              Icon3={list3Icon3}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

CallToActionContacto.propTypes = {
  iconTitle1: PropTypes.string.isRequired,
  iconTitle2: PropTypes.string.isRequired,
  iconTitle3: PropTypes.string.isRequired,
  title1: PropTypes.string.isRequired,
  title2: PropTypes.string.isRequired,
  title3: PropTypes.string.isRequired,
  list1Div1: PropTypes.string.isRequired,
  list1Div2: PropTypes.string.isRequired,
  list1Div3: PropTypes.string.isRequired,
  list2Div1: PropTypes.string.isRequired,
  list2Div2: PropTypes.string.isRequired,
  list2Div3: PropTypes.string.isRequired,
  list3Div1: PropTypes.string.isRequired,
  list3Div2: PropTypes.string.isRequired,
  list3Div3: PropTypes.string.isRequired,
}

export default CallToActionContacto
