import React from 'react'
import GoogleMapReact from 'google-map-react'

const defaultProps = {
  center: {
    lat: 10.4645885,
    lng: -73.2932692,
  },
  zoom: 15,
}

const AnyReactComponent = ({ text }) => <div>{text}</div>

const GoogleMap = ({ latitud, longitud, apiKey }) => (
  <div style={{ height: '60vh', width: '100%' }}>
    <GoogleMapReact
      bootstrapURLKeys={{ key: apiKey }}
      defaultCenter={defaultProps.center}
      defaultZoom={defaultProps.zoom}
    >
      <AnyReactComponent lat={latitud} lng={longitud} text={'Kreyser Avrora'} />
    </GoogleMapReact>
  </div>
)

export default GoogleMap
