import React, { useCallback } from 'react'

import Layout from 'components/layout'
import HeroComponent from 'components/hero'
import CallToActionContacto from 'components/callToActionContacto'
import Formulario from 'components/formulario'
import GoogleMap from 'components/googleMaps'
import Seo from 'components/seo'

import { graphql } from 'gatsby'

const ContactoPage = ({ data, location }) => {
  const {
    sanityContactoPage: {
      tabsContacto: {
        seo,
        Hero,
        contacto,
        googleMap,
        Call_to_Action_Contacto: {
          title3,
          title2,
          title1,
          item3lista1,
          item3lista2,
          item3lista3,
          item2lista3,
          item2lista2,
          item2lista1,
          item1lista3,
          item1lista2,
          item1lista1,
          iconItem1List1,
          iconItem1List2,
          iconItem1List3,
          iconItem2List1,
          iconItem2List2,
          iconItem2List3,
          iconItem3List1,
          iconItem3List2,
          iconTitle1,
          iconTitle2,
          iconTitle3,
        },
      },
    },
    sanityAppereance: {
      tabsAppereance: { header, footer },
    },
  } = data

  const setCallToActionBtn = useCallback(() => {
    console.log('click btn contáctanos')
  }, [])
  return (
    <Layout header={header} footer={footer}>
      {seo?.seo?.focus_keyword && seo?.author && seo?.seo?.focus_synonyms && (
        <Seo data={seo} />
      )}
      {Hero?.enable && (
        <HeroComponent
          navbar={Hero?.logoEmpresaHeader?.image?.asset?.url}
          navbarAlt={Hero?.logoEmpresaHeader?.alt}
          imageBackground={Hero?.image.image.asset.url}
          title={Hero?.title}
          subTitle={Hero?.description}
          btnText={Hero?.cta?.text}
          btnCallToAction={setCallToActionBtn}
          btnEnable={Hero?.cta?.enable}
          btnLink={Hero?.cta?.link}
          location={location.pathname}
        />
      )}
      <div className="encabezado">
        <h6 className="subtituloContacto text-center mt-4">
          {contacto?.title}
        </h6>
        {/* <EncabezadoMain
          title={contacto?.encabezadoMain?.text}
          paragraph={contacto?.encabezadoMain?.description}
        /> */}
      </div>
      <CallToActionContacto
        iconTitle1={iconTitle1?.asset.url}
        iconTitle2={iconTitle2?.asset.url}
        iconTitle3={iconTitle3?.asset.url}
        title1={title1}
        title2={title2}
        title3={title3}
        list1Icon1={iconItem1List1?.asset.url}
        list1Icon2={iconItem2List1?.asset.url}
        list1Icon3={iconItem3List1?.asset.url}
        list1Div1={item1lista1}
        list1Div2={item2lista1}
        list1Div3={item3lista1}
        list2Icon1={iconItem1List2?.asset.url}
        list2Icon2={iconItem2List2?.asset.url}
        list2Icon3={iconItem3List2?.asset.url}
        list2Div1={item1lista2}
        list2Div2={item2lista2}
        list2Div3={item3lista2}
        list3Icon1={iconItem1List3?.asset.url}
        list3Icon2={iconItem2List3?.asset.url}
        list3Icon3={iconItem1List3?.asset.url}
        list3Div1={item1lista3}
        list3Div2={item2lista3}
        list3Div3={item3lista3}
      />
      <Formulario />
      <GoogleMap
        latitud={googleMap.latitud}
        longitud={googleMap.longitud}
        apiKey={googleMap.apiKey}
      />
    </Layout>
  )
}

export const ContactoQuery = graphql`
  query ContactoQuery {
    sanityContactoPage(slug: { current: { eq: "contacto" } }) {
      tabsContacto {
        seo {
          author
          _key
          seo {
            seo_title
            meta_description
            focus_synonyms
            focus_keyword
            _type
            _key
          }
          ogImage {
            asset {
              url
            }
          }
        }
        Hero {
          description
          enable
          title
          cta {
            enable
            link
            text
          }
          image {
            image {
              asset {
                url
              }
            }
          }
          logoEmpresaHeader {
            image {
              asset {
                url
              }
            }
            alt
          }
        }
        contacto {
          enable
          title
        }
        googleMap {
          apiKey
          enable
          longitud
          latitud
        }
        Call_to_Action_Contacto {
          title3
          title2
          title1
          item3lista1
          item3lista2
          item3lista3
          item2lista3
          item2lista2
          item2lista1
          item1lista3
          item1lista2
          item1lista1
          iconItem1List1 {
            asset {
              url
            }
          }
          iconItem1List2 {
            asset {
              url
            }
          }
          iconItem1List3 {
            asset {
              url
            }
          }
          iconItem2List1 {
            asset {
              url
            }
          }
          iconItem2List2 {
            asset {
              url
            }
          }
          iconItem2List3 {
            asset {
              url
            }
          }
          iconItem3List1 {
            asset {
              url
            }
          }
          iconItem3List2 {
            asset {
              url
            }
          }
          iconItem3List3 {
            asset {
              url
            }
          }
          iconTitle1 {
            asset {
              url
            }
          }
          iconTitle2 {
            asset {
              url
            }
          }
          iconTitle3 {
            asset {
              url
            }
          }
        }
      }
    }
    sanityAppereance(slug: { current: { eq: "apariencia" } }) {
      tabsAppereance {
        footer {
          footerTitle
          footerParagrah
          enableFooter
          direccion
          departamento
          correo3
          correo2
          correo1
          logoEmpresaFooter {
            alt
            image {
              asset {
                url
              }
            }
          }
        }
        header {
          correo
          enableHeader
          linkFacebook
          linkInstagram
          linkLinkedin
          linkTwitter
          messenger
          telefono
        }
      }
    }
  }
`

export default ContactoPage
